<template>
  <div id="Walle">
    <div class="walle-lists">
      <div class="walle-list-item" v-for="(item, i) in walleList" :key="i">
        <a-row :gutter="[16, 16]">
          <a-col span="24">
            <div class="left">
              <div class="image">
                <div class="image-transform">
                  <img
                    :alt="item.currency"
                    :src="`${$public}/images/logo/currency/${item.currency}.png`"
                    :srcset="`
                      ${$public}/images/logo/currency/${item.currency}-30w.png 576w,
                      ${$public}/images/logo/currency/${item.currency}-60w.png 768w,
                      ${$public}/images/logo/currency/${item.currency}-120w.png 1920w,
                    `"
                    :style="{ width: '100%', height: '100%' }"
                  />
                </div>
              </div>
              <div class="right">
                {{ item.currency }}
              </div>
            </div>
            <div class="right">
              <router-link
                :to="{ name: 'Change-Record', params: { type: item.currency } }"
              >
                <a-icon type="file-text" />
                <span>账单</span>
              </router-link>
            </div>
          </a-col>
          <a-col span="24">
            <a-row :gutter="[15, 15]">
              <a-col :span="12">
                <div>
                  <p>
                    总资产
                    <a-tooltip arrow-point-at-center placement="topRight">
                      <template slot="title">
                        <span>可用余额 + 收益锁仓 + 扇区质押</span>
                      </template>
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </p>
                  <p>{{ item.total }}</p>
                </div>
              </a-col>
              <template
                v-if="item.currency !== 'USDT' && item.currency !== 'ALEO' && item.currency !== 'SMH'"
              >
                <a-col :span="12">
                  <div>
                    <p>
                      可用余额&nbsp;
                      <a-tooltip arrow-point-at-center placement="topRight">
                        <template slot="title">
                          <span>可提现FIL数量</span>
                        </template>
                        <a-icon type="info-circle" />
                      </a-tooltip>
                    </p>
                    <p>{{ item.available_balance }}</p>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div>
                    <p>
                      收益锁仓&nbsp;
                      <a-tooltip arrow-point-at-center placement="topLeft">
                        <template slot="title">
                          <span> 根据官方释放规则进行线性释放 </span>
                        </template>
                        <a-icon type="info-circle" />
                      </a-tooltip>
                    </p>
                    <p>{{ item.lock_pledge }}</p>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div>
                    <p>
                      扇区质押&nbsp;
                      <a-tooltip arrow-point-at-center placement="topRight">
                        <template slot="title">
                          <span
                            >购买机器540天后，根据官方释放规则进行线性释放</span
                          >
                        </template>
                        <a-icon type="info-circle" />
                      </a-tooltip>
                    </p>
                    <p>{{ item.sector_pledge }}</p>
                  </div>
                </a-col>
              </template>
              <a-col :span="24">
                <a-row>
                  <a-col :span="12" v-if="false && item.recharge_in_process">
                    <div>
                      <p>充币处理中&nbsp;</p>
                      <p>{{ item.recharge_in_process }}</p>
                    </div>
                  </a-col>
                  <a-col :span="12" v-if="item.withdraw_in_process">
                    <div>
                      <p :title="`提币冻结中`">提币冻结中&nbsp;</p>
                      <p>{{ item.withdraw_in_process }}</p>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="24" class="button">
            <div class="button-body">
              <a-button>
                <router-link
                  :to="{ name: 'Walle-Pay', params: { type: item.currency } }"
                >
                  充值
                </router-link>
              </a-button>
            </div>
            <div class="button-body">
              <a-button>
                <router-link
                  :to="{
                    name: 'Walle-Withdraw',
                    params: { type: item.currency },
                  }"
                >
                  提现
                </router-link>
              </a-button>
            </div>
            <div class="button-body" v-if="false">
              <a-button>
                <router-link
                  :to="{
                    name: 'Walle-Transfer-Accounts',
                    params: { type: item.currency },
                  }"
                >
                  转账
                </router-link>
              </a-button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Tooltip } from "ant-design-vue";
Vue.use(Tooltip);

export default {
  name: "Walle",
  // 数据
  data: () => {
    const a = JSON.parse(sessionStorage.getItem("walleList"));
    return {
      walleList: a ? a : [{}],
    };
  },
  // 方法
  methods: {
    async getInfo() {
      await this.$axios
        .get("personal-center/wallets", {
          params: { id: this.$route.params.id },
        })
        .then((res) => {
          if (res.code === 0) {
            this.walleList = res.data;
            sessionStorage.setItem("walleList", JSON.stringify(res.data));
          }
        });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
#Walle {
  .walle-lists {
    > .walle-list-item {
      background-color: #3d447b;
      line-height: 2.3em;
      padding: 1em;
      border-radius: 1em;
      margin-bottom: 1em;

      > .ant-row {
        > .ant-col {
          &:first-child {
            display: flex;
            align-items: center;

            > .left {
              display: flex;
              align-items: center;

              > .image {
                width: 46px;
              }

              > .right {
                height: 1em;
                line-height: 1em;
                font-size: 1.5em;
              }
            }
          }

          &:nth-child(2) .ant-col > div > p {
            line-height: 1.8em;

            &:first-child {
              font-size: 0.8em;
              color: #bcbcbc;
            }

            &:last-child {
              margin-left: 0.5em;
            }
          }

          &:nth-child(3).button {
            display: flex;
            justify-content: space-evenly;
            position: relative;

            > .button-body {
              width: 100%;

              > .ant-btn {
                border: 0;

                &:first-child {
                  margin-right: 1px;
                }

                &:last-child {
                  margin-left: 1px;
                }
              }
            }

            .button-body + .button-body {
              position: relative;

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%); /* Opera */
                -ms-transform: translate(-50%, -50%); /* IE 9 */
                -moz-transform: translate(-50%, -50%); /* Firefox */
                -webkit-transform: translate(-50%, -50%); /* Safari 和 Chrome */
                @marginTB: 0.5em;
                display: inline-block;
                width: 1px;
                height: calc(100% - (@marginTB * 2));
                background-color: white;
              }
            }
          }
        }

        > .ant-col + .ant-col {
          border-top: 2px solid #303463;
        }
      }
    }
  }
}
</style>